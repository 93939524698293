/** @jsx jsx */
import { Box, Image, Grid, Text, AspectRatio, jsx } from "theme-ui"
import React from "react"

// icons
import BalconyIcon from "../../images/balcony.svg"
import LandscapingIcon from "../../images/landscaping.svg"
import TerrariumsIcon from "../../images/terrariums.svg"
import PlantsIcon from "../../images/plants.svg"
import GiftingIcon from "../../images/gift.svg"
import { Roll } from "react-awesome-reveal"

interface CategoryProps {
  image: string
  text: string
  href: string
}

const Category = (props: CategoryProps) => {
  const { image, text } = props
  return (
    <Roll triggerOnce={true} duration={500}>
      <Box>
        <a href={props.href} target="_blank">
          <AspectRatio
            ratio={1 / 0.8}
            sx={{
              textAlign: "center",
              borderRadius: "1em",
              "&:hover .categoryImage": { transform: "scale(1.2)" },
            }}
            p={4}
            bg="highlight4"
          >
            <Image
              src={image}
              alt={text}
              className="categoryImage"
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
                transition: "transform 0.3s",
              }}
            />
          </AspectRatio>
          <Text
            variant="secondary"
            sx={{ fontSize: [1, 2, null, 3], textAlign: "center" }}
            mt={2}
          >
            {text}
          </Text>
        </a>
      </Box>
    </Roll>
  )
}

const CategoriesSection = (): JSX.Element => {
  return (
    <Grid
      columns={[2, null, 3, null, 5]}
      gap={[3, null, 4, 5]}
      p={3}
      sx={{ maxWidth: "1300px", margin: "auto" }}
    >
      <Category
        image={BalconyIcon}
        text="Balcony / Terrace Gardens"
        href="https://ygc.istaka.store/s?c=37"
      />
      <Category
        image={LandscapingIcon}
        text="Landscaping"
        href="https://ygc.istaka.store/s?c=32"
      />
      <Category
        image={TerrariumsIcon}
        text="Terrariums"
        href="https://ygc.istaka.store/s?c=35"
      />
      <Category
        image={PlantsIcon}
        text="Plants & Planters"
        href="https://ygc.istaka.store/s?c=34"
      />
      <Category image={GiftingIcon} text="Corporate Gifting" href="#" />
    </Grid>
  )
}

export default CategoriesSection
