/** @jsx jsx */
import Banner from "../components/Banner"
import { Text, Grid, Box, Image, AspectRatio, jsx } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ContentSection from "../components/ContentSection"
import { Slide, Zoom } from "react-awesome-reveal"

// images
import IstakapazaLogo from "../../images/istakapazaLogo.svg"
import CategoriesSection from "../components/CategoriesSection"

interface ProductInterface {
  image: any
  header: string
  href: string
}

const Product = (props: ProductInterface) => {
  const { image, header } = props
  return (
    <Box>
      <a
        href={props.href}
        target="_blank"
        sx={{
          "&:hover .productImage": { transform: "scale(1.2)" },
          overflow: "hidden",
        }}
      >
        <Zoom triggerOnce={true} duration={500}>
          <AspectRatio ratio={1 / 1} sx={{ overflow: "hidden" }}>
            <Img
              fluid={image}
              alt={header}
              className="productImage"
              sx={{
                width: "100%",
                height: "100%",
                transition: "transform 0.3s",
              }}
            />
          </AspectRatio>
        </Zoom>
        <Slide triggerOnce={true} duration={500} direction="down">
          <Text
            variant="default"
            sx={{
              fontSize: 3,
              fontWeight: "bold",
            }}
            color="accent"
            mt={2}
          >
            {header}
          </Text>
        </Slide>
        <Slide triggerOnce={true} duration={500} direction="up">
          <Text sx={{ color: "primary", fontSize: 3 }}>Shop Now</Text>
        </Slide>
      </a>
    </Box>
  )
}

export const productFluidImage = graphql`
  fragment productFluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const productImage = graphql`
  fragment productImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    mobileBanner: file(relativePath: { eq: "productMobileBanner.png" }) {
      ...productFluidImage
    }
    desktopBanner: file(relativePath: { eq: "productDesktopBanner.png" }) {
      ...productFluidImage
    }
    hangingGlobeTerrarium1: file(
      relativePath: { eq: "stonewareSucculentPlanter.jpg" }
    ) {
      ...productImage
    }
    hangingGlobeTerrarium2: file(
      relativePath: { eq: "hangingGlobeTerrarium2.png" }
    ) {
      ...productImage
    }
    hangingGlobeTerrarium3: file(relativePath: { eq: "desktopZenGarden.jpg" }) {
      ...productImage
    }
    hangingGlobeTerrarium4: file(
      relativePath: { eq: "hangingGlobeTerrarium4.png" }
    ) {
      ...productImage
    }
    hangingGlobeTerrarium5: file(
      relativePath: { eq: "hangingGlobeTerrarium5.png" }
    ) {
      ...productImage
    }
    hangingGlobeTerrarium6: file(
      relativePath: { eq: "hangingGlobeTerrarium6.png" }
    ) {
      ...productImage
    }
  }
`

export default function Products({ data }) {
  return (
    <Layout>
      <SEO title="Products" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Our Products"
        body={
          <Text variant="default" color="accent">
            Choose from a carefully assorted collection of nature made
            masterpieces that fits right on palm of your hands.
          </Text>
        }
        alt="product"
      />
      <ContentSection heading="Top Categories" body={<CategoriesSection />} />
      <ContentSection
        heading="Featured Products"
        body={
          <Grid columns={[1, 3]} gap={5} m={5}>
            <Product
              header="Stoneware succulent planter"
              image={data.hangingGlobeTerrarium1.childImageSharp.fluid}
              href={
                "https://ygc.istaka.store/p/Stoneware-succulent-planter/4622"
              }
            />
            <Product
              header="Hanging Pear Terrarium"
              image={data.hangingGlobeTerrarium2.childImageSharp.fluid}
              href={"https://ygc.istaka.store/p/Hanging-Pear-Terrarium/4612"}
            />
            <Product
              header="Desktop Zen Garden"
              image={data.hangingGlobeTerrarium3.childImageSharp.fluid}
              href={
                "https://ygc.istaka.store/p/Desktop-Zen-Garden-Medium/40901"
              }
            />
            <Product
              header="Candle Plantler"
              image={data.hangingGlobeTerrarium4.childImageSharp.fluid}
              href={"https://ygc.istaka.store/p/Candle-Planter/4617"}
            />
            <Product
              header="Mouth Globe Terrarium"
              image={data.hangingGlobeTerrarium5.childImageSharp.fluid}
              href={"https://ygc.istaka.store/p/Mouth-Globe-Terrarium/4613"}
            />
            <Product
              header="Sand Art Terrarium"
              image={data.hangingGlobeTerrarium6.childImageSharp.fluid}
              href={"https://ygc.istaka.store/p/Sand-Art-Terrarium/4615"}
            />
          </Grid>
        }
      />
      <Box bg="highlight5" m={5} p={4} sx={{ textAlign: "center" }}>
        <Text variant="default" color="accent2">
          Shop securly from our marketplace powered by www.istakapaza.com
        </Text>
        <Box mt={2}>
          <a href="https://ygc.istaka.store" target="_blank">
            <Image src={IstakapazaLogo} alt="Istakapaza" />
          </a>
        </Box>
      </Box>
    </Layout>
  )
}
